
















import AceEditor from 'vuejs-ace-editor';
import 'brace/ext/language_tools';
import 'brace/mode/html';
import 'brace/theme/terminal';
import 'brace/snippets/html';
import { Vue, Component, VModel } from 'vue-property-decorator';

@Component({
  components: {
    AceEditor,
  },
})
export default class EditorHtml extends Vue {
  @VModel({ type: String }) text!: string;

  editorOptions = {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    fontSize: 14,
    highlightActiveLine: true,
    enableSnippets: true,
    showLineNumbers: true,
    tabSize: 2,
  };
}
