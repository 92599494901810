































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ViewerHtml extends Vue {
  @Prop({ default: '' })
  public html!: string;

  @Prop({ default: '' })
  public name!: string;

  dialog = false;

  get formattedName(): string {
    return this.name ? ` - ${this.name}` : '';
  }
}
