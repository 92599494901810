







































import { Vue, Component, PropSync, Emit } from 'vue-property-decorator';
import LayoutEmailModel from '@/models/configurator/layouts/layout-email.model';

@Component({})
export default class ConfigTemplateCardList extends Vue {
  @PropSync('layouts', { required: true, default: [] })
  listLayouts!: LayoutEmailModel[];

  loader = this.$loading.show();

  mounted(): void {
    this.loader.hide();
  }

  toEditModel!: LayoutEmailModel;

  @Emit('onEdit')
  onClickToEdit(e: LayoutEmailModel): LayoutEmailModel {
    this.toEditModel = e;
    return this.toEditModel;
  }

  @Emit('onRemove')
  onClickToRemove(e: LayoutEmailModel): LayoutEmailModel {
    this.toEditModel = e;
    return this.toEditModel;
  }

  @Emit('onDuplicate')
  onClickDuplicate(e: LayoutEmailModel): LayoutEmailModel {
    this.toEditModel = { ...e };
    this.toEditModel.isDefault = false;
    this.toEditModel.code = -1;
    this.toEditModel.name = '';
    return this.toEditModel;
  }
}
