









































import { Vue, Component, Prop, Emit, VModel } from 'vue-property-decorator';
import EditorHtml from '@/components/editor-html.vue';
import ViewerHtml from '@/components/configurator/viewers/viewer-html.vue';
import ViewerVariableText from '@/components/configurator/viewers/viewer-variable-text.vue';
import VariableTextModel from '@/models/configurator/variables/variable-text.model';
import DraggableList from '@/components/configurator/draggables/draggable-list.vue';
import LayoutEmailModel from '@/models/configurator/layouts/layout-email.model';
import VariableColumnModel from '@/models/configurator/variables/variable-column.model';

@Component({
  components: {
    EditorHtml,
    ViewerHtml,
    ViewerVariableText,
    DraggableList,
  },
})
export default class ConfigTemplateCardFrom extends Vue {
  @Prop() variables!: VariableTextModel[];

  @Prop() columnOptions!: VariableColumnModel[];

  @VModel()
  layout!: LayoutEmailModel;

  items = ['Portrait', 'Landscape'];

  @Emit('onSaveClick')
  onSaveClick(): LayoutEmailModel {
    return this.layout;
  }
}
