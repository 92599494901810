














import { Vue, Component } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import VariableTextModel from '@/models/configurator/variables/variable-text.model';
import LayoutEmailModel from '@/models/configurator/layouts/layout-email.model';
import DraggableList from '@/components/configurator/draggables/draggable-list.vue';
import ConfigTemplateCard from '@/components/configurator/cards/email/config-template-card.vue';
import LayoutEmailService from '@/services/configurator/layouts/layout-email.service';
import VariableTextService from '@/services/configurator/variables/variable-text.service';
import VariableColumnService from '@/services/configurator/variables/variable-column.service';
import VariableColumnModel from '@/models/configurator/variables/variable-column.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';

@Component({
  components: {
    ConfigTemplateCard,
    DraggableList,
  },
})
export default class ConfigTemplateEmail extends Vue {
  @inject(InjectionIdEnum.ConfiguratorLayoutEmailService)
  private layoutEmailService!: LayoutEmailService;

  @inject(InjectionIdEnum.ConfiguratorVariableTextService)
  private variableTextService!: VariableTextService;

  @inject(InjectionIdEnum.ConfiguratorVariableColumnService)
  private variableColumnService!: VariableColumnService;

  variables: VariableTextModel[] = [];

  layouts: LayoutEmailModel[] = [];

  defaultTemplate: LayoutEmailModel = new LayoutEmailModel();

  columns: VariableColumnModel[] = [];

  async afterSave(): Promise<void> {
    this.layouts = await this.layoutEmailService.getLayouts();
  }

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    try {
      const [layouts, variables, columns, defTemplate] = await Promise.all([
        this.layoutEmailService.getLayouts(),
        this.variableTextService.getVariables(),
        this.variableColumnService.loadColumns(),
        this.layoutEmailService.getDefaultLayout(),
      ]);
      this.layouts = layouts;
      this.variables = variables;
      this.defaultTemplate = defTemplate;
      this.columns = columns || [];
    } catch (error) {
      if (error instanceof Error) {
        this.$notify.error(`Error on retrieving data.<br><b>ERROR</b>: ${error && error.message}`);
      }
    } finally {
      loader.hide();
    }
  }
}
