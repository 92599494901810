




































import { Vue, Component, PropSync, Prop, Emit } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import ConfigTemplateCardForm from '@/components/configurator/cards/email/config-template-card-form.vue';
import ConfigTemplateCardList from '@/components/configurator/cards/email/config-template-card-list.vue';
import LayoutEmailModel from '@/models/configurator/layouts/layout-email.model';
import VariableTextModel from '@/models/configurator/variables/variable-text.model';
import LayoutEmailService from '@/services/configurator/layouts/layout-email.service';
import VariableColumnModel from '@/models/configurator/variables/variable-column.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import NotificationModel from '@/models/notification.model';
import { NotificationTypeEnum } from '@/enums/notification-type.enum';

@Component({
  components: {
    ConfigTemplateCardForm,
    ConfigTemplateCardList,
  },
})
export default class ConfigTemplateCard extends Vue {
  @inject(InjectionIdEnum.ConfiguratorLayoutEmailService)
  private layoutEmailService!: LayoutEmailService;

  @PropSync('layouts', { required: true })
  listLayouts!: LayoutEmailModel[];

  @PropSync('variables', { required: true })
  listVariables!: VariableTextModel[];

  @Prop()
  columnOptions!: VariableColumnModel[];

  @Prop()
  defaultTemplate!: LayoutEmailModel;

  @Emit('afterSave')
  afterSaveMethod(e: LayoutEmailModel): LayoutEmailModel {
    this.activeTab = 'tab-list';
    this.$notify.show(
      new NotificationModel('Layout "saved" successfully!', NotificationTypeEnum.Success),
    );
    this.layoutModel = e;
    return e;
  }

  activeTab = 'tab-list';

  layoutModel: LayoutEmailModel = new LayoutEmailModel();

  listOptions: VariableColumnModel[] = [];

  get isListTab(): boolean {
    return this.activeTab === 'tab-list';
  }

  async saveLayout(): Promise<void> {
    const layoutToSave = { ...this.layoutModel };

    if (!layoutToSave.name) {
      this.$notify.show(new NotificationModel('Layout "Name" can\'t be empty'));
    } else if (!layoutToSave.html) {
      this.$notify.show(new NotificationModel('Layout "HTML" can\'t be empty'));
    } else if (!layoutToSave.columns || !layoutToSave.columns.length) {
      this.$notify.show(new NotificationModel('Layout "Columns" can\'t be empty'));
      this.$vuetify.goTo(500);
    } else {
      layoutToSave.columns = layoutToSave.columns.map((column, index) => ({
        ...column,
        position: index,
      }));

      if (layoutToSave.code === -1) {
        this.listLayouts.push(layoutToSave);
      }

      const loader = this.$loading.show();
      try {
        await this.layoutEmailService.save(layoutToSave);
        this.afterSaveMethod(layoutToSave);
      } catch (err) {
        this.$notify.show(
          new NotificationModel(
            `Error on saving.<br><b>ERROR</b>: ${err && (err as Error).message}`,
          ),
        );
        throw err;
      } finally {
        loader.hide();
      }
    }
  }

  async removeLayout(layout: LayoutEmailModel): Promise<void> {
    const loader = this.$loading.show();
    try {
      await this.layoutEmailService.remove(layout.code);
      const layoutIndex = this.listLayouts.findIndex(({ code }) => code === layout.code);
      this.listLayouts.splice(layoutIndex, 1);
      this.$notify.show(
        new NotificationModel('Layout "removed" successfully!', NotificationTypeEnum.Success),
      );
    } catch (err) {
      this.$notify.show(
        new NotificationModel(
          `Error on deleting.<br><b>ERROR</b>: ${err && (err as Error).message}`,
        ),
      );
      throw err;
    } finally {
      loader.hide();
    }
  }

  floatAction(): void {
    if (this.activeTab === 'tab-form') {
      this.activeTab = 'tab-list';
    } else if (this.activeTab === 'tab-list') {
      this.layoutModel = JSON.parse(JSON.stringify(this.defaultTemplate));
      this.listOptions = JSON.parse(JSON.stringify(this.columnOptions));
      this.activeTab = 'tab-form';
    }
  }

  editLayout(layout: LayoutEmailModel): void {
    this.layoutModel = layout;
    this.listOptions = this.columnOptions.filter(
      (option) => !this.layoutModel.columns.some(({ id }) => id === option.id),
    );
    this.layoutModel.columns = this.layoutModel.columns.sort(
      (a: VariableColumnModel, b: VariableColumnModel) => a.position - b.position,
    );
    this.activeTab = 'tab-form';
  }
}
